import { useEffect } from 'react';
import Script from 'next/script';
import { useCartId, useCartUpdateAttributes } from '@backpackjs/storefront';

const mId =
  process.env.NEXT_PUBLIC_FUELED_MERCHANT_ID ||
  '53616c7465645f5fd48ca0d5f8e061369be440200484413c5366ef0cf6ba0777842400cdb07e71ccc0847fcb50ac4b30c6ffe17880e4620a0943a5a3e666d3cd';
const fueledCdnUrl =
  process.env.NEXT_PUBLIC_FUELED_CDN_URL || 'https://cdn-client.fueled.io';

const eventTypes = [
  'dl_add_to_cart',
  'dl_view_item',
  'dl_select_item',
  'dl_view_item_list',
  'dl_view_search_results',
  'dl_view_cart',
  'dl_remove_from_cart',
  'dl_sign_up',
  'dl_login',
  'dl_subscribe',
  'dl_route_update',
];

export function FueledClientScript() {
  const cartId = useCartId();
  const { cartUpdateAttributes } = useCartUpdateAttributes();

  // accumulate events until the fueled script is loaded
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.packdigital = window.dataLayer?.packdigital || [];

    const eventHandler = (event) => {
      window.dataLayer.packdigital.push({
        type: event.type,
        detail: event.detail,
      });
    };

    eventTypes.forEach((eventType) => {
      window.addEventListener(eventType, eventHandler);
    });

    return () => {
      eventTypes.forEach((eventType) => {
        window.removeEventListener(eventType, eventHandler);
      });
    };
  }, []);

  useEffect(() => {
    if (!cartId) return () => null;

    let cleanCartId = cartId.replace('gid://shopify/Cart/', '');
    cleanCartId = cleanCartId.split('?')[0]; // Remove any trailing query strings added to the cartId.

    const setCartToken = async () => {
      const fueled = window.fueled || {};

      const res = await fueled.setFrontendStorage(
        fueled.config.frontendStorageUrl,
        cleanCartId
      );

      const {
        context: {
          ga4: { clientId = undefined, sessionId = undefined } = {},
          fb: { fbp = undefined, fbc = undefined } = {},
        },
      } = await res.json();

      await cartUpdateAttributes({
        attributes: [
          {
            key: 'fueled_context',
            value: JSON.stringify({
              ga4: { clientId, sessionId },
              fb: { fbp, fbc },
            }),
          },
        ],
      });
    };

    if (window.fueled) {
      setCartToken();
    } else {
      window.addEventListener('fueled-ready', setCartToken);
    }

    return () => {
      window.removeEventListener('fueled-ready', setCartToken);
    };
  }, [cartId]);

  return (
    <Script
      type="text/javascript"
      src={`${fueledCdnUrl}/fueled/${mId}/packdigital/js`}
    />
  );
}
